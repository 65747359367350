import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import '../css/style.css'; // Import your CSS file

const Blog = () => {
    const [blogPosts, setBlogPosts] = useState([]);
    const [loading, setLoading] = useState(true); // Loading state
    const [error, setError] = useState(null); // Error state

    useEffect(() => {
        const fetchBlogPosts = async () => {
            try {
                const response = await axios.get('https://serve-d3eo.onrender.com/api/blogs');
                console.log('Fetched blog posts:', response.data); // Log data to inspect
                setBlogPosts(response.data);
            } catch (error) {
                console.error('Error fetching blog posts:', error);
                setError('Error fetching blog posts');
            } finally {
                setLoading(false); // Set loading to false after fetching
            }
        };

        fetchBlogPosts();
    }, []);

    const createSlug = (title) => {
        return title
            .toLowerCase()
            .replace(/[^a-z0-9]+/g, '-') // Replace non-alphanumeric characters with hyphens
            .replace(/^-|-$/g, ''); // Remove leading and trailing hyphens
    };


    if (loading) return <p>Loading...</p>; // Display loading state
    if (error) return <p>{error}</p>; // Display error state

    return (
        <div className="blog">
            <div className="container">
                <div className="section-header">
                    <h2>Latest From Blog</h2>
                </div>
                <div className="row blog-page">
                {blogPosts.map(post => (
                        <div key={post._id} className="col-lg-4 col-md-6 blog-item">
                            <img src={`${post.image}`} alt="Blog" /> {/* Updated field */}
                            <div className="meta">
                                <i className="fa fa-tags"></i>
                                {post.tags.join(', ')} {/* Display tags */}
                                {post.publish ? <span>Published</span> : <span>Draft</span>} {/* Display publish status */}
                            </div>
                            <p>{post.title.substring(0, 100)}...</p> {/* Display content snippet */}
                            <Link className="btn" to={`/blogDetails/${createSlug(post.title)}/${post._id}`}>
                            Read More <i className="fa fa-angle-right"></i>
                            </Link>
                            {/* <a className="btn" href="#">Read More <i className="fa fa-angle-right"></i></a> */}
                        </div>
                    ))}
                </div>
                {/* <div className="row">
                    <div className="col-12">
                        <ul className="pagination justify-content-center">
                            <li className="page-item disabled"><a className="page-link" href="#">Previous</a></li>
                            <li className="page-item active"><a className="page-link" href="#">1</a></li>
                            <li className="page-item"><a className="page-link" href="#">2</a></li>
                            <li className="page-item"><a className="page-link" href="#">3</a></li>
                            <li className="page-item"><a className="page-link" href="#">Next</a></li>
                        </ul> 
                    </div>
                </div> */}
            </div>
        </div>
    );
};

export default Blog;
