import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom'; // Import useParams
import '../css/blogDetails.css'; // Import your CSS file
// import '../css/blogDetails.css'; // Import specific CSS for blog details

const BlogDetails = () => {
    const { slug, id } = useParams(); // Get the blog ID from URL params
    const [blogPost, setBlogPost] = useState(null);
    const [relatedBlogs, setRelatedBlogs] = useState([]);
    const [loading, setLoading] = useState(); // Loading state
    const [error, setError] = useState(null); // Error state

    useEffect(() => {
        const fetchBlogDetails = async () => {
            try {
                const response = await axios.get(`https://serve-d3eo.onrender.com/api/blogs/${id}`);
                setBlogPost(response.data);

                // Fetch related blogs (excluding the current one)
                const relatedResponse = await axios.get(`https://serve-d3eo.onrender.com/api/blogs?exclude=${id}`);
                setRelatedBlogs(relatedResponse.data);
            } catch (error) {
                console.error('Error fetching blog details:', error);
                setError('Error fetching blog details');
            } finally {
                setLoading(false); // Set loading to false after fetching
            }
        };

        fetchBlogDetails();
    }, [id]);

    const createSlug = (title) => {
        return title
            .toLowerCase()
            .replace(/[^a-z0-9]+/g, '-') // Replace non-alphanumeric characters with hyphens
            .replace(/^-|-$/g, ''); // Remove leading and trailing hyphens
    };

    if (loading) return <p>Loading...</p>; // Display loading state
    if (error) return <p>{error}</p>; // Display error state

    return (
        <div className="container-blog">
            <div className="single">
                {/* <div className="section-header"> */}
                    {blogPost && (
                        <>
                            <h1>{blogPost.title}</h1>
                            {/* Uncomment and use if image is available */}
                            {/* <img src={`http://localhost:5000/uploads/${blogPost.image}`} alt={blogPost.title} /> */}
                            <div className="blog-detail-content" dangerouslySetInnerHTML={{ __html: blogPost.content }} />
                            {/* <p>{blogPost.}</p> */}
                        </>
                    )}
                {/* </div> */}
            </div>
            <div className="related-blogs">
                <h2>Related Blogs</h2>
                <ul>
                    {relatedBlogs.map(blog => (
                        <li key={blog._id}>
                        <img src={`${blog.image}`} alt="Blog" />
                        <a href={`/blogDetails/${createSlug(blog.title)}/${blog._id}`}>
                            <p>{blog.title.substring(0, 70)}...</p>
                        </a>
                    </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default BlogDetails;
